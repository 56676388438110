// Package Imports

// import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslate } from '@refinedev/core';

// Package Imports

import { useTokenContext } from 'contexts/resource/ResourceContext';
import getFormattedDate from 'core/utils/getFormattedDate';

const Notification = ({ notificationsVisible }: any) => {
  const t = useTranslate();
  const { notificationMessages, setNotificationMessages } = useTokenContext();
  const [showReadMsg, setShowReadMsg] = useState(true);
  const [filteredNotificationMsgs, setFilteredNotificationMsgs] =
    useState(notificationMessages);
  const handleClear = () => {
    setNotificationMessages([]);
    if (localStorage.getItem('notifications') !== null) {
      localStorage.removeItem('notifications');
    }
  };
  const handleClose = () => {
    const updatedNotificationMsg = notificationMessages?.map((message) => ({
      ...message,
      read: true,
    }));
    setNotificationMessages(updatedNotificationMsg);
    localStorage.setItem(
      'notifications',
      JSON.stringify(updatedNotificationMsg)
    );
  };
  function handleMsgToggle() {
    setShowReadMsg(!showReadMsg);
  }
  useEffect(() => {
    if (notificationMessages) {
      if (showReadMsg) {
        setFilteredNotificationMsgs(notificationMessages);
      } else {
        const unreadMsgs =
          notificationMessages?.filter((msg) => !msg?.read) || [];
        setFilteredNotificationMsgs(unreadMsgs);
      }
    }
  }, [showReadMsg]);

  return notificationsVisible ? (
    <div
      className="fixed top-[64px]  flex w-screen max-w-max -translate-x-1/2 notification"
      style={{
        backgroundColor: '#f0f0f0',
      }}
    >
      <div className="notification-arrow" />
      <div className="w-screen max-w-md flex-auto  text-sm leading-6 shadow-lg ring-2 ring-gray-900/6 bg-gradient-to-r from-gray-50 to-blue-100">
        {/* <div> */}
        {/* show all msgs initially */}
        <div className="mt-4 ml-3 mb-1">
          {' '}
          <span className="text-black text-base">
            {t('pages.socketNotification.readUnreadMsg')}
          </span>
          <Switch
            defaultChecked
            onChange={handleMsgToggle}
            className="mx-2.5"
          />
        </div>
        {filteredNotificationMsgs && filteredNotificationMsgs?.length > 0 ? (
          <div className="overflow-y-auto max-h-[600px]">
            {filteredNotificationMsgs?.map((item, msgindex) => {
              const isSuccess = item.status === 'success';
              const isError = item.status === 'failed';
              console.log('item in socket', item);
              return (
                <div
                  key={msgindex}
                  className={`group gap-x-6 p-4 ${
                    isSuccess ? 'border-green-500 bg-green-50' : ''
                  } ${isError ? 'border-red-500 bg-red-50' : ''}`}
                  style={{
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderRadius: '10px',
                    margin: '10px',
                  }}
                >
                  <div className="flex gap-x-6 mb-4">
                    {/* <div

                    // className={`mt-1 flex h-11 w-11 flex-none items-center justify-center group-hover:bg-white ${
                    //   isSuccess ? 'bg-green-200' : ''
                    // } ${isError ? 'bg-red-200' : ''}`}
                    > */}
                    {/* {isSuccess ? (
                        <CheckCircleOutlined className="text-green-600" />
                      ) : (
                        <CloseCircleOutlined className="text-red-600" />
                      )} */}
                    {/* <Progress
                        type="circle"
                        percent={item?.progress}
                        size={50}
                        status={isError ? 'exception' : null}
                      /> */}
                    {/* </div> */}
                    <div>
                      {/* <a href="#" className="font-semibold text-gray-900"> */}
                      {item?.title}{' '}
                      <span className="text-xs text-gray-400">
                        {getFormattedDate(item?.timestamp)}
                      </span>
                      {/* </a> */}
                      <p className="mt-1 text-gray-600">{item?.message}</p>
                    </div>
                  </div>
                  {item?.extraKeys
                    ? Object.keys(item?.extraKeys)?.map((objectKey, index) => (
                        <p key={index}>
                          <span className="text-gray-600">{objectKey}</span>:{' '}
                          {item?.extraKeys[objectKey]}
                        </p>
                      ))
                    : null}
                </div>
              );
            })}
          </div>
        ) : null}

        <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-100">
          <button
            className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-blue-200 cursor-pointer"
            onClick={handleClear}
          >
            {t('buttons.clear')}
          </button>
          <button
            className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-blue-200 cursor-pointer"
            onClick={handleClose}
          >
            {t('buttons.close')}
          </button>
        </div>
      </div>
      {/* </div> */}
    </div>
  ) : null;
};

export default Notification;
